/* eslint-disable no-undef */

export const isDev = process?.env?.NODE_ENV === 'development'
export const isStaging = Boolean(process?.env?.IS_STAGING)
export const isProd = !isDev && !isStaging

// PRODUCTION
const productionSettings = {
  host: '',
  facebookAppId: '916005028434478',
  googleAppId: '471545811015-4m5ukhdhiv8k609ojf94ppo1dl0p84e9.apps.googleusercontent.com',
  stripeApiKey: 'pk_live_9RhqBxUbIBKdaFWWeBu32jxu',
  googleTagManagerId: 'GTM-TQXW43N',
  googleAnalyticsTrackingId: 'UA-53027127-1',
  googleMapsApiKey: 'AIzaSyBsyCs7sCuuHewIISgpb0K5oN1evsCOrmg',
  postcoderApiKey: 'PCWMF-VLPZF-8TM5V-ZGNGB',
  intercomAppId: 'dyjd9jkz'
}

// STAGING
const stagingSettings = {
  // host: 'https://staging.gowashmycar.com',
  host: '',
  facebookAppId: '916005028434478',
  googleAppId: '471545811015-4m5ukhdhiv8k609ojf94ppo1dl0p84e9.apps.googleusercontent.com',
  stripeApiKey: 'pk_test_8gk8C4ivJ9V87QwYbGu8gP7E',
  googleTagManagerId: 'GTM-TQXW43N',
  googleAnalyticsTrackingId: 'UA-53027127-1',
  googleMapsApiKey: 'AIzaSyBsyCs7sCuuHewIISgpb0K5oN1evsCOrmg',
  postcoderApiKey: 'PCWMF-VLPZF-8TM5V-ZGNGB',
  intercomAppId: 'ttq8mnta'
}

export const settings = isProd ? productionSettings : stagingSettings;